import React from 'react'

export default function ErrorMessage({ modalTitle, title, message, onClose }) {
    return (
        <>
            <h3>{modalTitle}</h3>

            <div className="warn-msg">
                <p>
                    <i className="fas fa-exclamation-triangle" />
                </p>
                <h4>{title}</h4>
                <div>{message}</div>
            </div>

            <div>
                <a
                    href="https://shipperhq.com/contact"
                    className="mb-2 btn btn-primary"
                >
                    Contact Support
                </a>
            </div>

            <a className="primary-link" onClick={onClose}>
                Close
            </a>
        </>
    )
}
