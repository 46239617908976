import React from 'react'

export default function ProgressBar({ progress }) {
    return (
        <div className="scope-progress-bar">
            <div style={{ width: `${progress}%` }}>
                <span>{progress}%</span>
            </div>
        </div>
    )
}
