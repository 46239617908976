import React, { useEffect, useState } from 'react'
import Modal from 'components/ui/Modal'
import ProgressIndicator from './ProgressIndicator'
import SuccessMessage from './SuccessMessage'
import ErrorMessage from './ErrorMessage'

const POLLING_DELAY = 10000

const ACTION_LABELS = {
    bigcommerce_migrate_products_metafields: 'Migrating BigCommerce Metafields',
    import_origins: 'Origins import',
    import_boxes: 'Boxes import',
    import_table_rates: 'Table Rates import',
    import_customer_groups: 'Customer groups import',
    import_shipping_groups: 'Shipping groups import',
    import_ship_filters: 'Filters',
    import_zones: 'Zones',
    import_locations: 'Locations',
}

const IMPORT_DESCRIPTION =
    'You will not be able to make any changes to your account while your file is uploading. Closing or navigating away from this screen will not affect upload progress.'

const ACTION_DESCRIPTION = {
    import_origins: IMPORT_DESCRIPTION,
    import_boxes: IMPORT_DESCRIPTION,
    import_customer_groups: IMPORT_DESCRIPTION,
    import_shipping_groups: IMPORT_DESCRIPTION,
    import_ship_filters: IMPORT_DESCRIPTION,
    import_zones: IMPORT_DESCRIPTION,
    import_table_rates: IMPORT_DESCRIPTION,
    import_locations: IMPORT_DESCRIPTION,
}

const ActionProgressModal = ({
    open,
    onClose,
    actionProgress,
    loading,
    children,
    onRefetch,
}) => {
    const {
        inProgress,
        processLabel,
        processDescription,
        progress,
        isSuccess,
        isPartiallyFailed,
    } = useActionProgressModal({ open, actionProgress, onRefetch })

    if (!open || loading) return null

    return (
        <div className="scope-sect">
            <Modal onClose={onClose} className="modal-content">
                {inProgress ? (
                    <ProgressIndicator
                        name={processLabel}
                        description={processDescription}
                        progress={progress}
                    />
                ) : isSuccess ? (
                    <SuccessMessage
                        title="Success"
                        modalTitle={processLabel}
                        message={children}
                        onClose={onClose}
                    />
                ) : (
                    <ErrorMessage
                        title={
                            isPartiallyFailed ? 'Partially failed' : 'Failure'
                        }
                        modalTitle={processLabel}
                        message={children}
                        onClose={onClose}
                    />
                )}
            </Modal>
        </div>
    )
}

export const useActionProgressModal = ({ open, actionProgress, onRefetch }) => {
    const inProgress = actionProgress
        ? ['in_progress', 'unstarted'].includes(actionProgress.status)
        : true

    useInterval(
        open && inProgress && onRefetch ? onRefetch : null,
        POLLING_DELAY
    )

    if (!actionProgress) return {}

    const processLabel = ACTION_LABELS[actionProgress.name]
    const processDescription = ACTION_DESCRIPTION[actionProgress.name]

    const progress = actionProgress.progress || 0
    const isSuccess = actionProgress.status === 'succeed'
    const isPartiallyFailed = actionProgress.status === 'partially_failed'

    return {
        inProgress,
        processLabel,
        processDescription,
        progress,
        isSuccess,
        isPartiallyFailed,
    }
}

const useInterval = (callback, delay) => {
    const [intervalId, setIntervalId] = useState(null)

    useEffect(() => {
        if (callback) {
            intervalId && clearInterval(intervalId)
            setIntervalId(setInterval(callback, delay))
        } else {
            intervalId && clearInterval(intervalId)
        }
    }, [callback, delay])
}

export default ActionProgressModal
