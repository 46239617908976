import React from 'react'
import ProgressBar from 'components/dashboard/scopes/components/ProgressBar'

export default function ProgressIndicator({ name, description, progress }) {
    return (
        <>
            <h3>{name} is being started</h3>
            <div className="modal-well">
                <p>
                    {description
                        ? description
                        : `The process of ${name} all of the settings on your
                    configuration may take several minutes.`}
                </p>

                <ProgressBar progress={progress} />

                {/* <p>
                    We will notify you via email when this process is complete
                </p> */}
            </div>
        </>
    )
}
