import React from 'react'

export default function SuccessMessage({
    title,
    modalTitle,
    message,
    onClose,
}) {
    return (
        <>
            <h3>{modalTitle}</h3>
            <div className="success-msg">
                <p>
                    <i className="fas fa-check-circle" />
                </p>
                <h4>{title}</h4>
                <div>{message}</div>
            </div>

            <button className="btn btn-primary" onClick={onClose}>
                Ok, Great!
            </button>
        </>
    )
}
